// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json'
};

// Get DDL Account Type
export const GetDDLAccountTypeAction = async () => {
    const response = await Request.get( 'Market/SelectDDLAccountType', {headers});
    return response.data;
}

// Get DDL Bank
export const GetDDLBankAction = async () => {
    const response = await Request.get( 'Market/SelectDDLBank', {headers});
    return response.data;
}

// Get DDL Bank
export const GetDDLMarketerClassAction = async (lang: string) => {
    const response = await Request.get( 'Market/SelectDDLMarketerClass/' + lang );
    return response.data;
}

// Get DDL Country
export const GetDDLCountryAction = async () => {
    const response = await Request.get('Market/SelectDDLCountry');
    return response.data;
}

// Get DDL City
export const GetDDLCityAction = async (ID: number) => {
    const response = await Request.get('Market/SelectDDLCity/' + ID);
    return response.data;
}

// Get MarketerAccountData
export const GetMarketerAccountDataAction = async () => {
    const response = await Request.get( 'Market/GetMarketerBankAccountOne', {headers});
    return response.data;
}

export interface CreateMarketerAccountInterface {
    ACCOUNT_TYPE_ID: number,
    CAPTION: string,
    EMAIL: string,
    MOBILE: string,
    MARKETER_BANK_ACCOUNT_ID: number
    IBAN: string,
    BANK_ID: number,
    CITY_ID: number,
    ACCOUNT_NO: number,
    IS_ACTIVE_Y_N: string,
    mode: number,
}

export const CreateMarketerAccountInsertAction = async ( Payload: any ) => {
    const response = await Request.post('/Market/MARKETERBANKInsert', Payload, {headers} )
    return response.data;
}

export const CreateMarketerAttachmentInsertAction = async ( Payload: any ) => {
    const response = await Request.post('/Market/MarketerAttachmentInsert', Payload, {headers} )
    return response.data;
}

export const UploadAttachmentInsertAction = async ( Payload: any ) => {
    const response = await Request.post('Market/MarketerAttachmentUpload', Payload, {
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data'
    }});
    return response.data;
}