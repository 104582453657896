// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Master Store
import { RootState } from "../MasterStore";
// Actions
import { GetDDLAccountTypeAction, GetDDLBankAction, GetDDLMarketerClassAction, GetDDLCityAction, GetDDLCountryAction, GetMarketerAccountDataAction, UploadAttachmentInsertAction, CreateMarketerAccountInsertAction, CreateMarketerAttachmentInsertAction, CreateMarketerAccountInterface } from '../Actions/BankAccount';

// Interface
interface IBankAccount {
    loading?: boolean;
    bankAcountDDL?: any;
    bankDDL?: any;
    countryDDL?: any;
    cityDDL?: any;
    marketerAccountData?: any;
    marketerClass?: any;
    error?: any;
}

// Initial State
const initialState: IBankAccount = {
    loading: false,
    bankAcountDDL: [],
    bankDDL: [],
    cityDDL: [],
    countryDDL: [],
    marketerAccountData: [],
    marketerClass: [],
    error: ''
}

// Get DDL Account Type
export const GetDDLAccountTypeSlice = createAsyncThunk(
    'DDLAccountType',
    async () => {
        return await GetDDLAccountTypeAction();
    }
)

// Get DDL Bank
export const GetDDLBankSlice = createAsyncThunk(
    'DDLBank',
    async () => {
        return await GetDDLBankAction();
    }
)

// Get DDL Country
export const GetDDLCountrySlice = createAsyncThunk(
    'DDLCountry',
    async () => {
        return await GetDDLCountryAction();
    }
)

// Get DDL City
export const GetDDLCitySlice = createAsyncThunk(
    'DDLCity',
    async (ID: number) => {
        return await GetDDLCityAction(ID);
    }
)

// Get DDL MarketerClass
export const GetDDLMarketerClassSlice = createAsyncThunk(
    'DDLMarketerClass',
    async (lang: string) => {
        return await GetDDLMarketerClassAction(lang);
    }
)

// Get MarketerAccountData
export const GetMarketerAccountDataSlice = createAsyncThunk(
    'MarketerAccountData',
    async () => {
        return await GetMarketerAccountDataAction();
    }
)

// MarketerAccountInsert
export const CreateMarketerAccountInsertSlice = createAsyncThunk(
    'MarketerAccountInsert',
    async (Payload: CreateMarketerAccountInterface) => {
        return await CreateMarketerAccountInsertAction(Payload);
    }
)

// MarketerAccountInsert
export const CreateMarketerAttachmentInsertSlice = createAsyncThunk(
    'MarketerAttachmentInsert',
    async (Payload: any) => {
        return await CreateMarketerAttachmentInsertAction(Payload);
    }
)

// MarketerAccountInsert
export const UploadAttachmentInsertSlice = createAsyncThunk(
    'UploadAttachmentInsert',
    async (Payload: any) => {
        return await UploadAttachmentInsertAction(Payload);
    }
)

const BankAccountSlice = createSlice({
    name: 'AccountType',
    initialState,
    reducers: {},
    extraReducers: (Builder) => {

        // Get DDL Account Type
        Builder.addCase(GetDDLAccountTypeSlice.pending, (State) => {
            State.loading = true;
        })
        Builder.addCase(GetDDLAccountTypeSlice.fulfilled, (State, Action) => {
            State.loading = false;
            State.bankAcountDDL = Action.payload.DATA;
            State.error = '';
        })
        Builder.addCase(GetDDLAccountTypeSlice.rejected, (State, Action) => {
            State.loading = false;
            State.bankAcountDDL = [];
            State.error = Action.error?.message;
        })

        // Get DDL Bank
        Builder.addCase(GetDDLBankSlice.pending, (State) => {
            State.loading = true;
        })
        Builder.addCase(GetDDLBankSlice.fulfilled, (State, Action) => {
            State.loading = false;
            State.bankDDL = Action.payload.DATA;
            State.error = '';
        })
        Builder.addCase(GetDDLBankSlice.rejected, (State, Action) => {
            State.loading = false;
            State.bankDDL = [];
            State.error = Action.error?.message;
        })

        // Get DDL Bank
        Builder.addCase(GetMarketerAccountDataSlice.pending, (State) => {
            State.loading = true;
        })
        Builder.addCase(GetMarketerAccountDataSlice.fulfilled, (State, Action) => {
            State.loading = false;
            State.marketerAccountData = Action.payload.DATA;
            State.error = '';
        })
        Builder.addCase(GetMarketerAccountDataSlice.rejected, (State, Action) => {
            State.loading = false;
            State.marketerAccountData = [];
            State.error = Action.error?.message;
        })

        // Get DDL Country
        Builder.addCase(GetDDLCountrySlice.pending, (State) => {
            State.loading = true;
        })
        Builder.addCase(GetDDLCountrySlice.fulfilled, (State, Action) => {
            State.loading = false;
            State.countryDDL = Action.payload.DATA;
            State.error = '';
        })
        Builder.addCase(GetDDLCountrySlice.rejected, (State, Action) => {
            State.loading = false;
            State.countryDDL = [];
            State.error = Action.error?.message;
        })

        // Get DDL City
        Builder.addCase(GetDDLCitySlice.pending, (State) => {
            State.loading = true;
        })
        Builder.addCase(GetDDLCitySlice.fulfilled, (State, Action) => {
            State.loading = false;
            State.cityDDL = Action.payload.DATA;
            State.error = '';
        })
        Builder.addCase(GetDDLCitySlice.rejected, (State, Action) => {
            State.loading = false;
            State.cityDDL = [];
            State.error = Action.error?.message;
        })

        // Get DDL MarketerClass
        Builder.addCase(GetDDLMarketerClassSlice.pending, (State) => {
            State.loading = true;
        })
        Builder.addCase(GetDDLMarketerClassSlice.fulfilled, (State, Action) => {
            State.loading = false;
            State.marketerClass = Action.payload.DATA;
            State.error = '';
        })
        Builder.addCase(GetDDLMarketerClassSlice.rejected, (State, Action) => {
            State.loading = false;
            State.marketerClass = [];
            State.error = Action.error?.message;
        })
    },
});

export const SelectBankAccount = ( State: RootState ) => State.BankAccount;
export default BankAccountSlice.reducer;