// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';


export interface SuggestedApplicationInterface {
    APPLICATION_NAME: string,
    APPLICATION_DESCRIPTION: string,
    APPLICATION_CATEGORY_ID: number,
    PLATFORM: string,
    TARGET_AUDIENCE: string,
    ADDITIONAL_COMMENTS: string,
}

const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json'
  };

export const CreateSuggestedApplicationAction = async ( Payload: SuggestedApplicationInterface ) => {
    const response = await Request.post('/Market/SuggestedApplication', Payload, {headers})
    return response.data;
}