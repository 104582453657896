// Redux Toolkit
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// Application Reducers
import UserSlice from "./Reducers/UserSlice";
import StateSlice from "./Reducers/StateSlice";
import OrderSlice from './Reducers/OrderSlice';
import PaymentSlice from './Reducers/PaymentSlice';
import ProductSlice from './Reducers/ProductSlice';
import LanguageSlice from "./Reducers/LanguageSlice";
import CategorySlice from "./Reducers/CategorySlice";
import BankAccountSlice from './Reducers/BankAccountSlice';
import UserProductSlice from './Reducers/UserProductSlice';
import PaymentRecordSlice from './Reducers/PaymentRecordSlice';
import MarketProgramSlice from './Reducers/MarketProgramSlice';
import SuggestedApplicationSlice from './Reducers/SuggestedApplicationSlice';


export const MasterStore = configureStore({
    reducer: {
        User: UserSlice,
        Order:OrderSlice,
        State: StateSlice,
        Product: ProductSlice,
        Payment: PaymentSlice,
        Language: LanguageSlice,
        Category: CategorySlice,
        UserProduct: UserProductSlice,
        BankAccount: BankAccountSlice,
        PaymentRecord:PaymentRecordSlice,
        MarketProgram: MarketProgramSlice,
        SuggestedApplication: SuggestedApplicationSlice,
    },
});

// Export RootState and AppDispatch
export type AppDispatch = typeof MasterStore.dispatch;
export type RootState = ReturnType<typeof MasterStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction< ReturnType, RootState, unknown, Action<string>>;