import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateSuggestedApplicationAction, SuggestedApplicationInterface } from "../Actions/SuggestedApplication";
import { RootState } from "../MasterStore";

interface TSuggestedApplication {
    loading?: boolean;
    SuggestedApplication?: any;
    error?: any;
}

// Initial State
const initialState: TSuggestedApplication = {
    loading: false,
    SuggestedApplication: {},
    error: ''
}

// Create Payment Record
export const CreateSuggestedApplication = createAsyncThunk(
    'Market/SuggestedApplication',
    async (payload: SuggestedApplicationInterface) => {        
        return  await CreateSuggestedApplicationAction(payload);
    }
)

const SuggestedApplicationSlice = createSlice({
    name: 'Payment',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {
    
        // Create Payment Record
        Builder.addCase( CreateSuggestedApplication.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateSuggestedApplication.fulfilled, ( State, Action) => {
            State.loading = false;
            State.SuggestedApplication = Action.payload;
            State.error = '';
        })
        Builder.addCase( CreateSuggestedApplication.rejected ,( State, Action) => {
            State.loading = false;
            State.SuggestedApplication = {};
            State.error = Action.error?.message;
        })
    },
});

export const Selectorder = ( State: RootState ) => State.SuggestedApplication;
export default SuggestedApplicationSlice.reducer;