import React ,{ lazy, useState, FC } from 'react';

// React Router
import { Navigate, useRoutes  } from 'react-router-dom';

// Lazy Loading
const Home = lazy(() => import('../Pages/Home/Home'));
const WhyUs = lazy(() => import('../Pages/WhyUs/WhyUs'));
const New = lazy(() => import('../Pages/NewDesign/New'));
const Finance = lazy(()=> import('../Pages/Finance/Finance'));
const Pricing = lazy(() => import('../Pages/Pricing/Pricing'));
const Profile = lazy(() => import('../Pages/Profile/Profile'));
const Container = lazy(() => import('../Containers/Container'));
const AddUsers = lazy(()=> import('../Pages/AddUsers/AddUsers'));
const Features = lazy(() => import('../Pages/Features/Features'));
const Error404 = lazy(() => import('../Pages/Error404/Error404'));
const Checkout = lazy(() => import('../Pages/CheckOut/Checkout'));
const AddNewGroup = lazy(()=> import('../Pages/AddNewGroup/AddNewGroup'));
const MarketPlace = lazy(() => import('../Pages/MarketPlace/Marketplace'));
const AccountBank = lazy(() => import('../Pages/AccountBank/AccountBank'));
const SalesReports = lazy(() => import('../Pages/SalesReports/SalesReports'));
const CheckOutFaild = lazy(()=> import('../Pages/CheckOutFaild/CheckOutFaild'));
const PrivacyPolicy = lazy(()=> import('../Pages/PrivacyPolicy/PrivacyPolicy'));
const ActiveCoupons = lazy(() => import('../Pages/ActiveCoupons/ActiveCoupons'));
const MarketingHome = lazy(() => import('../Pages/MarketingHome/MarketingHome'));
const ContainerMarketing = lazy(() => import('../Containers/ContainerMarketing'));
const ContainerAccounting = lazy(() => import('../Containers/ContainerAccounting'));
const ProductDetails = lazy(() => import('../Pages/ProductDetails/ProductDetails'));
const CheckoutDetails = lazy(()=> import('../Pages/CheckOutDetails/CheckOutDetails'));
const PublisherProducts = lazy(() => import('../Pages/PublisherProducts/PublisherProducts'));
const ApplicationBrowse = lazy(() => import('../Pages/ApplicationBrowse/ApplicationBrowse'));
const TermsAndConditions = lazy(()=> import('../Pages/TermsAndConditions/TermsAndConditions'));
const ActivationRequests = lazy(() => import('../Pages/ActivationRequests/ActivationRequests'));
const SubscriptionsAccount = lazy(()=> import('../Pages/SubscriptionsAccount/SubscriptionsAccount'));
const BrowseApplicationDetail = lazy(() => import('../Pages/BrowseApplicationDetail/BrowseApplicationDetail'));
const SubscriptionsManagement = lazy(()=> import('../Pages/SubscriptionsManagement/SubscriptionsManagement'));

const MasterRoutes: FC  = () => {
    const [Path] = useState('/');
    const IsAuthenticated = !!localStorage.token;
    const checkAuthenticated = (element: React.ReactNode) => {
        return IsAuthenticated ? element : <Navigate to={'/'} replace />;
    };

    return useRoutes([
        {
            path: Path,
            element: <Container />,
            children: [
                { path: Path, element: <Home /> },
                { path: 'why-us', element: <WhyUs /> },
                { path: 'features', element: <Features /> },
                { path: 'pricing', element: <Pricing /> },
                { path: 'new', element: <New /> },
                { path: 'marketplace', element: <MarketPlace /> },
                { path: 'productDetails/:id', element: <ProductDetails /> },
                { path: 'publisherProducts/:id', element: <PublisherProducts /> },
                {
                    path: 'checkOut/:pricingId/:id',
                    element: checkAuthenticated(<Checkout />)
                },
                {
                    path: 'checkoutFaild',
                    element: checkAuthenticated(<CheckOutFaild />)
                },
                {
                    path: 'checkoutDetails',
                    element: checkAuthenticated(<CheckoutDetails />)
                },
                {
                    path: 'subscriptionsManagement',
                    element: checkAuthenticated(<SubscriptionsManagement />)
                },
                {path: 'termsAndConditions',element: <TermsAndConditions />},
                {path: 'privacypolicy',element: <PrivacyPolicy />}
            ]
        },
        {
			path: Path,
            element: <ContainerMarketing />,
			children: [
				{ path: 'finance', element: checkAuthenticated(<Finance />) },
				{ path: 'account-bank', element: checkAuthenticated(<AccountBank />) },
				{ path: 'sales-reports', element: checkAuthenticated(<SalesReports />) },
				{ path: 'marketing-home', element: checkAuthenticated(<MarketingHome />) },
				{ path: 'active-coupons', element: checkAuthenticated(<ActiveCoupons />) },
				{ path: 'application-browse', element: checkAuthenticated(<ApplicationBrowse />) },
				{ path: 'activation-requests', element: checkAuthenticated(<ActivationRequests />) },
				{ path: 'browse-application-detail/:id', element: checkAuthenticated(<BrowseApplicationDetail />) }
			]
		},
        {
			path: Path,
            element: <ContainerAccounting />,
			children: [
                { path: 'addUsers/:userId?', element: checkAuthenticated(<AddUsers />) },
				{ path: 'addNewGroup/:projectCode?/:roleId?', element: checkAuthenticated(<AddNewGroup />) },
				{ path: 'subscriptions-account', element: checkAuthenticated(<SubscriptionsAccount />) }
			]
		},
        {
			path: Path + 'profile',
			children: [
				{ path: '', element: checkAuthenticated(<Profile />) }
			]
		},
        { path: Path + '*', element: <Error404 /> },
    ])
}

export default MasterRoutes;
